@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  display: flex;
  animation: marquee 20s linear infinite;
}